import cn from 'classnames';
import { useRouter } from 'next/router';
import type { MouseEventHandler } from 'react';
import React, { memo, useCallback } from 'react';

import { Button } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import type { AffSub4 } from '@src/@types/microcredits';
import { getButtonValue } from '@src/components/AdvertisingButton/utils';
import { TURBOZAIM_AB_TEST_PIXEL_CLICK } from '@src/constants/abTest';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { MONETIZATION_INTERNAL } from '@src/helpers/advertising';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import { sendAdvertisingButtonClickEvent } from '@src/helpers/analyticsEvents';
import { open } from '@src/helpers/navigationHelper';
import { usePixel } from '@src/hooks/usePixel';
import { isSpecialPageWithoutQuery } from '@src/utils/routing';

import styles from './styles.module.scss';

interface IProps extends ButtonProps {
    className?: string;
    advSub?: string;
    source?: string;
    analytics: IAnalyticsItem;
    productLinkHref?: string;
    affSub4?: AffSub4;
    shouldGetAffId?: boolean;
    onClick?: () => void;
}

export const AdvertisingButton: React.FunctionComponent<IProps> = memo(
    ({
        className,
        source,
        analytics,
        advSub,
        productLinkHref,
        shouldGetAffId = true,
        onClick,
        affSub4,
        ...buttonProps
    }) => {
        const router = useRouter();
        const { asPath } = router;
        const isSpecial = isSpecialPageWithoutQuery(asPath);
        const link = shouldGetAffId
            ? analytics.advertising?.monetization?.pixels?.click
            : TURBOZAIM_AB_TEST_PIXEL_CLICK;
        const monetizationKind = analytics.advertising?.monetization?.kind;
        const isDetailsPage = asPath.startsWith('/zaimy/mfo/');

        const buttonValue = getButtonValue({ isDetailsPage, isSpecial });

        const href = usePixel(link, {
            sub: advSub,
            affSub4,
            type: analytics.advertising?.productType,
            source,
            pixelType: PIXELS_TYPES.CLICK,
            shouldGetAffId,
        });

        const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
            (event) => {
                event.stopPropagation();
                event.preventDefault();
                sendAdvertisingButtonClickEvent(analytics);

                onClick?.();

                if (buttonProps.variant === 'secondary' && productLinkHref) {
                    router.push(productLinkHref);
                    return;
                }

                if (!href) {
                    return;
                }

                if (monetizationKind === MONETIZATION_INTERNAL) {
                    router.push(href);
                    return;
                }

                if (href) {
                    open({
                        href,
                        target: '_blank',
                        features: 'noopener',
                    });
                }
            },
            [analytics, onClick, buttonProps.variant, productLinkHref, href, monetizationKind, router],
        );

        return (
            <a className={cn(styles.link, className)} onClick={handleClick}>
                <Button
                    className={isSpecial || isDetailsPage ? styles.button : styles.buttonFake}
                    variant="primary"
                    {...buttonProps}
                >
                    {buttonValue}
                </Button>
            </a>
        );
    },
);
