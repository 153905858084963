export const getButtonValue = ({ isDetailsPage, isSpecial }: { isDetailsPage: boolean; isSpecial: boolean }) => {
    switch (true) {
        case isDetailsPage:
            return 'Получить займ';

        case isSpecial:
            return 'Получить деньги';

        default:
            return 'Перейти на сайт';
    }
};
